@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    font-family: 'Poppins', sans-serif;
    color: #333;
    line-height: 1.6;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
}

.logoContainer {
    display: flex;
    flex-direction: column;
}

.logo {
    font-size: 24px;
    font-weight: 700;
    color: #2c7744;
}

.subtitle {
    font-size: 12px;
    color: #666;
}

.nav a {
    margin-left: 20px;
    text-decoration: none;
    color: #333;
    font-weight: 500;
}

.bentoGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 40px 0;
}

.bentoItem {
    background-color: #f0f7f4;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.bentoItem:hover {
    transform: translateY(-5px);
}

.hero {
    grid-column: span 3;
    text-align: center;
    background-color: #2c7744;
    color: white;
}

.heroImg {
    width: 100%;
    max-width: 400px;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
}

.hero h1 {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 20px;
}

.hero p {
    font-size: 24px;
    max-width: 600px;
    margin: 0 auto 20px;
}

.heroImagePlaceholder {
    width: 100%;
    height: 300px;
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-top: 20px;
}

.about {
    grid-column: span 3;
}

.features {
    grid-column: span 3;
}

.featureGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.featureCard {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.featureCard h3 {
    color: #2c7744;
    margin-bottom: 10px;
}

.impact {
    grid-column: span 3;
}

.stats {
    display: flex;
    justify-content: space-around;
    text-align: center;
    margin-bottom: 20px;
}

.number {
    font-size: 36px;
    font-weight: 700;
    color: #2c7744;
    display: block;
}

.label {
    font-size: 16px;
}

.cta {
    grid-column: span 3;
    text-align: center;
}

.contactInfo {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.button {
    display: inline-block;
    padding: 12px 24px;
    background-color: #2c7744;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #1e5631;
}

.footer {
    text-align: center;
    padding: 40px 0;
    color: #333;
}

.footer a {
    color: #2c7744;
    text-decoration: none;
}

@media (max-width: 768px) {
    .bentoGrid {
        grid-template-columns: 1fr;
    }

    .hero, .about, .features, .impact, .cta {
        grid-column: span 1;
    }

    .stats {
        flex-direction: column;
        gap: 20px;
    }
}
